.menu-btn-container {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-between;
    width          : 130px;
    height         : 65px;
    position       : relative;
}

.menu-btn {
    background        : #eef3f7;
    background        : linear-gradient(180deg, #eef3f7 0%, #f5f8ff 80%);
    background        : -webkit-linear-gradient(180deg, #eef3f7 0%, #f5f8ff 80%);
    background        : -moz-linear-gradient(180deg, #eef3f7 0%, #f5f8ff 80%);
    width             : 130px;
    height            : 65px;
    border-radius     : 90px 30px 30px 30px;
    box-shadow        : -1px 0px 10px 3px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: -1px 0px 10px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow   : -1px 0px 10px 3px rgba(0, 0, 0, 0.25);
    transition        : opacity 0.5s;
    opacity           : 1;
    border            : 1px solid transparent;
    transition        : border-color 0.8s;
    z-index           : 0;
}

.menu-btn:hover,
.menu-btn:focus {
    opacity     : 0.8;
    border-color: #3fa9ff;
}

.menu-btn.selected {
    opacity     : 0.8;
    border-color: #6d0000;
}

.menu-icon-container {
    border-radius     : 50%;
    background        : #005296;
    background        : linear-gradient(180deg, #002b4e 0%, #007ee6 80%);
    background        : -webkit-linear-gradient(180deg, #002b4e 0%, #007ee6 80%);
    background        : -moz-linear-gradient(180deg, #002b4e 0%, #007ee6 80%);
    box-shadow        : -1px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow   : -1px 0px 5px 1px rgba(0, 0, 0, 0.2);
    width             : 36px;
    height            : 36px;
    align-self        : flex-start;
    position          : absolute;
    z-index           : 1;
    color             : #f5f8ff;
    font-size         : 22px;
    display           : flex;
    align-items       : center;
    justify-content   : center;

}

.menu-text-titre-container {
    max-width     : 130px;
    width         : 130px;
    height        : 60px;
    font-size     : 95%;
    padding-top   : 10px;
    margin-right  : 5px;
    text-align    : right;
    position      : absolute;
    z-index       : 2;
    font-weight   : bold;
    pointer-events: none;
}