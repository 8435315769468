.header-infos {

    //   background-color: rgb(0, 96, 160) !important;

    height     : $headerInfosHeight !important;
   
    position   : fixed;
    z-index    : 2;
    width      : 100%;
    display    : flex;
    align-items: center;
  
    color      : #fff !important;
   
    font-size  : 16px;
    font-weight: 600;

    opacity        : 1;
    transition     : opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;

    .visible {
        opacity: 1; 
      }
      
      .hidden {
        opacity: 0; 
      }
}

.text-container {

  width: 100%;
  height: $headerInfosHeight; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.line {
  height: $headerInfosHeight;
  font-size: 16px;
  font-weight: bold;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 // width: 100%;
  text-align: center;
}

.non-mobile .text-container {
  width: 50%; 
}

.mobile .text-container {
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateIn {
  0% {
    transform: rotateZ(-90deg) scale(0.2);
    opacity: 0;
  }
  100% {
    transform: rotateZ(0) scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-fade-in {
  animation: fadeIn 1s linear forwards;
}

.animate-fade-out {
  animation: fadeOut 1s linear forwards;
}

.animate-bounce-in {
  animation: bounceIn 1s ease-out forwards;
}

.animate-rotate-in {
  animation: rotateIn 1s ease-out forwards;
}

.animate-shake {
  animation: shake 0.5s ease-out forwards;
}

.animate-fade-in-from-left {
  animation: fadeInFromLeft 1s ease-out forwards;
}

.animate-fade-in-from-right {
  animation: fadeInFromRight 1s ease-out forwards;
}

.animate-fade-in-from-top {
  animation: fadeInFromTop 1s ease-out forwards;
}

.animate-fade-in-from-Bottom {
  animation: fadeInFromBottom 1s ease-out forwards;
}

.animate-zoom-in {
  animation: zoomIn 1s ease-out forwards;
}

.animate-bounce {
  animation: bounce 1s ease-out forwards;
}
