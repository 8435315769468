body {
    font-family            : $bodyFontDefault;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    //background-image: url('../medias/fond/001lightv3.webp');
    background-color: #FFFFFF !important;
    color: #000000 !important;
    
}


 @media (prefers-color-scheme: dark) {
    body {
        background-image: url('../medias/fond/001darkv2.webp'); 
        background-color: #000000;
        color: #FFFFFF;
    }
}


@media (prefers-color-scheme: light) {
    body {
        background-image: url('../medias/fond/001lightv3.webp');
        background-color: #ffffff;
        color: #000000;
    }
}

html {
    font-family            : $bodyFontDefault;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale; 
}