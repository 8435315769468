.ant-layout {
    display: flex;
    //background-color: white;
    background-color: transparent;
    //background-color: #FFFFFF !important;
    color: #000000 !important;
}

.ant-layout-footer {
    display        : flex;
    width          : 100%;
    align-items    : center;
    justify-content: center;
    font-size      : 13px !important;
    height         : $footerHeight;
    text-align     : center !important;
    font-weight    : 400 !important;
    background-color: rgba(255, 255, 255, 0.815);
}

.ant-layout-content {
    min-height     : $contentHeight !important;
    margin-top     : calc($headerMenuHeight);
    display        : flex;
   // align-items    : center;
   // justify-content: center;
    flex-direction : column;
    height: 100% !important;
    //background-color: rgb(230, 244, 253);
  
}
.ant-layout-header {
    background-color: rgba(255, 255, 255, 0.856) !important;
}
